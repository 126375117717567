/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2019 Colophon Foundry
 *
 * Licenced to Brian Hom
 */
@font-face {
  font-family: 'apercu-black-italic';
  src: url("./upward/fonts/apercu-black-italic.eot");
  src: url("./upward/fonts/apercu-black-italic.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-black-italic.woff2") format("woff2"), url("./upward/fonts/apercu-black-italic.woff") format("woff"), url("./upward/fonts/apercu-black-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-black';
  src: url("./upward/fonts/apercu-black.eot");
  src: url("./upward/fonts/apercu-black.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-black.woff2") format("woff2"), url("./upward/fonts/apercu-black.woff") format("woff"), url("./upward/fonts/apercu-black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-bold-italic';
  src: url("./upward/fonts/apercu-bold-italic.eot");
  src: url("./upward/fonts/apercu-bold-italic.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-bold-italic.woff2") format("woff2"), url("./upward/fonts/apercu-bold-italic.woff") format("woff"), url("./upward/fonts/apercu-bold-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-bold';
  src: url("./upward/fonts/apercu-bold.eot");
  src: url("./upward/fonts/apercu-bold.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-bold.woff2") format("woff2"), url("./upward/fonts/apercu-bold.woff") format("woff"), url("./upward/fonts/apercu-bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-extralight-italic';
  src: url("./upward/fonts/apercu-extralight-italic.eot");
  src: url("./upward/fonts/apercu-extralight-italic.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-extralight-italic.woff2") format("woff2"), url("./upward/fonts/apercu-extralight-italic.woff") format("woff"), url("./upward/fonts/apercu-extralight-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-extralight';
  src: url("./upward/fonts/apercu-extralight.eot");
  src: url("./upward/fonts/apercu-extralight.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-extralight.woff2") format("woff2"), url("./upward/fonts/apercu-extralight.woff") format("woff"), url("./upward/fonts/apercu-extralight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-italic';
  src: url("./upward/fonts/apercu-italic.eot");
  src: url("./upward/fonts/apercu-italic.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-italic.woff2") format("woff2"), url("./upward/fonts/apercu-italic.woff") format("woff"), url("./upward/fonts/apercu-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-light-italic';
  src: url("./upward/fonts/apercu-light-italic.eot");
  src: url("./upward/fonts/apercu-light-italic.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-light-italic.woff2") format("woff2"), url("./upward/fonts/apercu-light-italic.woff") format("woff"), url("./upward/fonts/apercu-light-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-light';
  src: url("./upward/fonts/apercu-light.eot");
  src: url("./upward/fonts/apercu-light.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-light.woff2") format("woff2"), url("./upward/fonts/apercu-light.woff") format("woff"), url("./upward/fonts/apercu-light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-medium-italic';
  src: url("./upward/fonts/apercu-medium-italic.eot");
  src: url("./upward/fonts/apercu-medium-italic.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-medium-italic.woff2") format("woff2"), url("./upward/fonts/apercu-medium-italic.woff") format("woff"), url("./upward/fonts/apercu-medium-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-medium';
  src: url("./upward/fonts/apercu-medium.eot");
  src: url("./upward/fonts/apercu-medium.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-medium.woff2") format("woff2"), url("./upward/fonts/apercu-medium.woff") format("woff"), url("./upward/fonts/apercu-medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-regular';
  src: url("./upward/fonts/apercu-regular.eot");
  src: url("./upward/fonts/apercu-regular.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-regular.woff2") format("woff2"), url("./upward/fonts/apercu-regular.woff") format("woff"), url("./upward/fonts/apercu-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-thin-italic';
  src: url("./upward/fonts/apercu-thin-italic.eot");
  src: url("./upward/fonts/apercu-thin-italic.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-thin-italic.woff2") format("woff2"), url("./upward/fonts/apercu-thin-italic.woff") format("woff"), url("./upward/fonts/apercu-thin-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'apercu-thin';
  src: url("./upward/fonts/apercu-thin.eot");
  src: url("./upward/fonts/apercu-thin.eot?#iefix") format("embedded-opentype"), url("./upward/fonts/apercu-thin.woff2") format("woff2"), url("./upward/fonts/apercu-thin.woff") format("woff"), url("./upward/fonts/apercu-thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
